@charset "utf-8";
// Import a Google Font
@import url('https://fonts.googleapis.com/css?family=Nunito:400,700');

@font-face {
    font-family: 'GoodTimes';
    src: url(../fonts/goodtimes.ttf) format('truetype')
}

@font-face {
    font-family: 'MandatoryPlaything';
    src: url(../fonts/MandatoryPlaything.otf) format('truetype')
}

@font-face {
    font-family: 'Neuropolitical';
    src: url(../fonts/neuropolitical.ttf) format('truetype')
}

$dark: #011;
$pale: #dadada;
$tint: #d1e5e1;
$danger-tint: #f4c4d3;

// Update Bulma's global variables
$family-sans-serif: "Nunito", sans-serif;
$primary: #A2CADE;
$body-background-color:$dark;

// Update some of Bulma's component variables
$control-border-width: 2px;
$input-border-color: transparent;
//$input-shadow: none;

// Import the rest of Bulma
@import "../../../node_modules/bulma/bulma.sass";
@import "../../../node_modules/bulmaswatch/flatly/bulmaswatch.scss";
@import "../../../node_modules/@vaettyr/boltcave-client-core/dist/esm/index.css";
@import "../../../node_modules/@creativebulma/bulma-tooltip/dist/bulma-tooltip.min.css"; //this needs to get bundled into client core

/*Font Awesome*/
$fa-font-path: '~@fortawesome/fontawesome-free/webfonts'; 
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/regular';

/*import our styles*/
@import "../../../node_modules/@vaettyr/boltcave-auth-client/dist/cjs/index.css";
@import "../../../node_modules/@vaettyr/boltcave-media-client/dist/cjs/index.css";
@import "./styles.scss";