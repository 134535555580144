article.media.single-article {
    position: relative;

    div.media-right {
        position: absolute;
        top: 0;
        right: 0;
    }
}

article.media.collapsible {
    display:grid;
    grid-template-columns: max-content 1fr max-content;

    &.inactive:not(.adjacent_1):not(.adjacent_-1) {
        max-height: 0;
        overflow:hidden;
        margin:0;
        padding:0;
        border:none;
    }

    &.inactive.adjacent_1 {
        mask-image:linear-gradient(0deg, rgba(0,0,0,0) 0%, $dark 100%);
        -webkit-mask-image:linear-gradient(0deg, rgba(0,0,0,0) 0%, $dark 100%);
    }

    &.inactive.adjacent_-1 {
        mask-image:linear-gradient(0deg, $dark 0%, rgba(0,0,0,0) 100%);
        -webkit-mask-image:linear-gradient(0deg, $dark 0%, rgba(0,0,0,0) 100%);
    }

    & > .inactive .media-right {
        pointer-events: none;
    }

    .collapse-body, progress, p.has-text-danger {
        grid-column: 1 / -1;
        width: 100%;
    }

    & > .collapse-header:first-child:before {
        content: none;
    }

    div.media-content.no-thumbnail {
        grid-column: 1 / span 2;
        padding-left: 0;
    }

    & > div.media-right {
        max-height: inherit;
        overflow-y:inherit;
    }

    .article-thumbnail
    {
        width: 6em;
    }

    .title
    {
        font-family: 'GOODTIMES';
    }

    &.new {
        .title, .subtitle {
            color: $pale;
        }
    }

    &.viewed {
        .title, .subtitle {
            color: #899;
        }  
    }

    &.updated {
        .title, .subtitle {
            color: #bc8;
        }
        .title:after {
            content: "\f0f3";
            display:inline-block;
            padding-left: 1em;
            opacity: 0.5;
            font-family: "Font Awesome 6 Free";
        }
    }

    .subtitle
    {
        color: $pale;
        font-family: 'MandatoryPlaything';
        position: relative;

        span:not(:first-child) {
            position: absolute;
            right:0;
        }
    }

    .content
    {
        font-family: 'Neuropolitical';
    }

    div.collapse-header:nth-child(2) {
        padding-left: 1em;
    }
}

.container-page {
    max-width:90vw;
    margin: 1em auto;
}

.icon.clickable {
    cursor:pointer;

    &.is-right {
        position: absolute;
        right: calc(5vw - 2.5em);
    }
}

div.article-image-picker {
    div.modal-content {
        width: fit-content;
    }
}

div.modal.edit-article {
    div.modal-content {
        width: 90vw;

        div.card-content {
            form {
                display: grid;
                grid-template-columns: 1fr 1fr;
                column-gap: 1em;

                div.jodit-react-container {
                    grid-column: 1 / -1;

                    div.jodit-toolbar__box {
                        z-index: 5000;
                    }
                }

                div.file {
                    justify-content: start;
                    flex-direction: column;
                    flex-grow: 1;

                    label.file-label {
                        flex-grow: 1;

                        span.file-cta {
                            flex-grow: 1;
                            justify-content: center;
                        }
                    }

                    span.file-name {
                        width: 100%;
                        max-width: 100%;
                    }
                }
            }
        }
    }
}