img.gallery {
    padding: 0.75em;
    cursor:pointer;
}

.gallery-view {
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 10000;
    background-color: rgba(0,0,0,0.5);

    img.image-gallery-thumbnail-image
    {
        max-height: 80px;
        width: auto;
    }

    span.close 
    {
        font-size: 4em;
        color: white;
        position: absolute;
        top: 0.5em;
        right: 0.1em;
        z-index: 10001;
        cursor:pointer;
    }
}