.edit-groups {
    .panel-block {
        display: grid;
        grid-template-columns: 1fr max-content;
        border-radius: 1em;

        .collapse-header {
            display:flex;
            grid-column: 1 / -1;

            label:first-child {
                font-size:large;
                font-family:"GoodTimes";
                margin-left: 1em;
                flex-grow: 1;
            }
        }

        &.is-deleted {
            background-color: $danger-tint;
        }

        &.active .collapse-header {
            margin-bottom: 0.75em;
            padding-bottom: 0.75em;
            border-bottom: 1px solid $dark;
        }
        
        .field {
            align-self: start;
            &:last-child {
                grid-column: 1 / -1;
            }
        }
    }
}