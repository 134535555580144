$dark: #011;
$pale: #dadada;
$tint: #d1e5e1;
$danger-tint: #f4c4d3;
$white: #fff;
/*import our styles*/
body {
    color: $pale;
}

span[data-tooltip] {
    border-bottom: none;
    position:absolute;
    z-index:20;
    &:before {
        z-index:40 !important;
    }
}

@import "~react-image-gallery/styles/scss/image-gallery.scss";
@import "./article.scss";
@import "./gallery.scss";
@import "./video.scss";
@import "./groups.scss";
@import "./nav.scss";