p.ta-insert-video {
    width: 90vw;
    margin: 5vw;
    display:inline-block;
    position:relative;

    &::after {
        position:absolute;
        font-family: "Font Awesome 6 Free";
        font-weight: 400;
        font-size:6em;
        content:"\f144";
        width:100%;
        height:100%;
        top:0;
        left:0;
        text-align: center;
        color: $white;
        line-height: 67.5vw;
        z-index: 100;
        pointer-events: none;
    }

    &.half {
        width: 45vw;
        margin:2.5vw;
        iframe.ta-insert-video {
            height: 33.75vw;
        }
        &::after {
            line-height:33.75vw;
        }
    }

    img.ta-insert-video {
        width:100%;       
        cursor:pointer;
    }

    iframe.ta-insert-video {
        width:100%;
        height: 67.5vw;
        position: relative;
        z-index:200;
    }
}